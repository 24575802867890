import { combineReducers } from 'redux'
// import farmDataReducer from './slices/farm_data_slice'
// import configDataReducer from './slices/config_slice'
import userReducer from './slices/userSlice'
import { reducer as toastr } from 'react-redux-toastr'
import firebase from 'firebase/app'
import firebaseConfig from '../firebase'
import 'firebase/firestore'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import { reducer as formReducer } from 'redux-form'
// @ts-ignore
import { IntlReducer as Intl } from 'react-redux-multilingual'

firebase.initializeApp(firebaseConfig)
firebase.firestore()
// import firebaseConfig from '../firebase'

// Initialize firebase instance
// firebase.initializeApp(firebaseConfig)
// firebase.firestore()

const rootReducer = combineReducers({
  Intl,
  userReducer,
  toastr,
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  form: formReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer