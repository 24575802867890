import { createSlice } from '@reduxjs/toolkit'
// import { FarmData, AppThunk, FarmStatuses, Farm } from '../types'
// import { toastr } from 'react-redux-toastr'

export interface ConfigState {
  user: any
  credential: any
  twitterProfile: any
}

const initialState: ConfigState = {
  user: null,
  credential: null,
  twitterProfile: {
  },
}

const configSlice = createSlice({
  name: 'userData',
  initialState: initialState,
  reducers: {
    setCredential(state, action) {
      if (action.payload !== {
      }) {
        state.credential = action.payload
      }
    },
    setUser(state, action) {
      // console.log('action == ', action)
      state.user = action.payload
    },
    setTwitterProfile(state, action) {
      state.twitterProfile = action.payload
    },
  },
})

export const {
  setCredential, setUser, setTwitterProfile,
} = configSlice.actions
export default configSlice.reducer
