export const MAIN_TEXT_INPUT_PLACEHOLDER =
  'You can compose your twitter thread here....\n\
\n\
Type your thread here and you can see it split up into individual tweets in the\n\
thread preview on the right of the page.\n\
\n\
You can add images to tweets by clicking the dropdown arrow in the top right \n\
hand corner of any tweet.\n\
\n\
To delete an image from a tweet, simply drag it to the dustbin icon at the top\n\
of the preview pane.'
