import { langEn } from './en/messages'

export const translations = {
  en: langEn,
  // zh: {
  //   locale: 'zh',
  //   messages: {
  //     hello: '你好！你好吗 {name}',
  //   },
  // },
}