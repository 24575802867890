import React, { Component } from 'react'
import TopNav from '../nav/TopNav'
// import Main from './Main'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from '../components/Loading'
// @ts-ignore
const TopNavWithRouter = withRouter(TopNav)
// import { connect } from 'react-redux'
interface Props {
  // userData: any
}
interface State { }

const MainContainer = Loadable({
  loader: () => import('./Main'),
  loading: Loading,
})

const DraftsContainer = Loadable({
  loader: () => import('./DraftsContainer/DraftsContainer'),
  loading: Loading,
})

const SentContainer = Loadable({
  loader: () => import('./SentContainer/SentContainer'),
  loading: Loading,
})

const ScheduleContainer = Loadable({
  loader: () => import('./ScheduleContainer/ScheduleContainer'),
  loading: Loading,
})

// @ts-ignore
const SettingsContainer = Loadable({
  // @ts-ignore
  loader: () => import('./SettingsContainer'),
  loading: Loading,
})

const ViewSentThreadContainer = Loadable({
  // @ts-ignore
  loader: () => import('./ViewSentThreadContainer/ViewSentThreadContainer'),
  loading: Loading,
})

class AppContainer extends Component<Props, State> {
  state = {
    user: null,
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <TopNavWithRouter />
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <Route path="/home" component={MainContainer} />
            <Route path="/drafts" component={DraftsContainer} />
            <Route path="/sent" component={SentContainer} />
            <Route path="/schedule" component={ScheduleContainer} />
            <Route path="/settings" component={() => <SettingsContainer />} />
            <Route path="/viewThread/:threadId" component={() => <ViewSentThreadContainer />} />
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

export default AppContainer