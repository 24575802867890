/* eslint-disable no-console */
// import React, { Component } from 'react'
import * as React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types'
import { pick, some } from 'lodash'
import { isLoaded } from 'react-redux-firebase'
import {
  mapProps, branch, renderComponent,
} from 'recompose'
import LoadingSpinner from '../components/LoadingSpinner'

// /**
//  * Show a loading spinner when a condition is truthy. Used within
//  * spinnerWhileLoading. Accepts a test function and a higher-order component.
//  * @param  {Function} condition - Condition function for when to show spinner
//  * @return {HigherOrderComponent}
//  */
export const spinnerWhile = (condition: any) =>
  branch(condition, renderComponent(LoadingSpinner))

/**
 * Show a loading spinner while props are loading . Checks
 * for undefined, null, or a value (as well as handling `auth.isLoaded` and
 * `profile.isLoaded`). **NOTE:** Meant to be used with props which are passed
 * as props from state.firebase using connect (from react-redux), which means
 * it could have unexpected results for other props
 * @example Spinner While Data Loading
 * import { compose } from 'redux'
 * import { connect } from 'react-redux'
 * import { firebaseConnect } from 'react-redux-firebase'
 *
 * const enhance = compose(
 *   firebaseConnect(['projects']),
 *   connect(({ firebase: { data: { projects } } })),
 *   spinnerWhileLoading(['projects'])
 * )
 *
 * export default enhance(SomeComponent)
 * @param  {Array} propNames - List of prop names to check loading for
 * @return {HigherOrderComponent}
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const spinnerWhileLoading = (propNames: any) =>
  spinnerWhile((props: any) => some((propNames: any, name: any) => !isLoaded(props[name])))

/**
 * HOC that logs props using console.log. Accepts an array list of prop names
 * to log, if none provided all props are logged. **NOTE:** Only props at
 * available to the HOC will be logged.
 * @example Log Single Prop
 * import { compose } from 'redux'
 * import { connect } from 'react-redux't-redux), which means
 * it could have unexpected results for other props
 * @example Spinner While Data Loading
 * import { firebaseConnect } from 'react-redux), which means
 * it could have unexpected results for other props
 * @example Spinner While Data Loading
 *
 * const enhance = compose(
 *   withProps(() => ({ projectName: 'test' })),
 *   logProps(['projectName']) // 'test' would be logged to console when SomeComponent is rendered
 * )
 *
 * export default enhance(SomeComponent)
 * @param  {Array} propNames - List of prop names to log. If none provided, all
 * are logged
 * @return {HigherOrderComponent}
 */
export const logProps = (propNames: any, logName = '') =>
  mapProps(ownerProps => {
    console.log(
      `${logName} props:`,
      propNames ? pick(ownerProps, propNames) : ownerProps,
    )
    return ownerProps
  })

export const createWithFromContext = (withVar: any) => (WrappedComponent: any) => {
  class WithFromContext extends Component {
    render() {
      const props = {
        [withVar]: this.context[withVar],
      }
      if (this.context.store && this.context.store.dispatch) {
        // @ts-ignore
        props.dispatch = this.context.store.dispatch
      }
      return <WrappedComponent {...this.props} {...props} />
    }
  }

  // @ts-ignore
  WithFromContext.contextTypes = {
    [withVar]: PropTypes.object.isRequired,
  }

  return WithFromContext
}

export const withRouter = createWithFromContext('router')

/**
 * HOC that adds store to props
 * @return {HigherOrderComponent}
 */
export const withStore = createWithFromContext('store')
