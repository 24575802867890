import React, { Component, createContext } from 'react'
import { auth, API_BASE_URL } from '../firebase'
import {
  setUser, setCredential, setTwitterProfile,
} from '../redux/slices/userSlice'
import { connect } from 'react-redux'
import LoadingSpinner from '../components/LoadingSpinner'
import { compose } from 'recompose'
import { spinnerWhileLoading, withRouter } from '../util/components'
import { firestoreConnect } from 'react-redux-firebase'
import { FirestoreCollections } from '@shared/constants'
import { TwitterCredentials, User } from '@shared/types/user'
import Axios from 'axios'
// import { toastr } from "react-redux-toastr"
// import { configureStore } from '../redux/createStore'

// const store = configureStore({})

export const UserContext = createContext({
  user: null,
})
interface Props {
  setUser: Function
  setCredential: Function
  credential: { uid: string, credential: TwitterCredentials },
  user: User,
  setTwitterProfile: Function,
}

class UserProvider extends Component<Props> {
  state = {
    user: null,
    loading: true,
    credentialSet: false,
  }

  componentDidMount = () => {
    auth().onAuthStateChanged(async (userAuth: any) => {
      this.setState({
        user: userAuth, loading: false,
      })
      this.props.setUser(userAuth)
      // const { credential } = this.props.credential
      // console.log('props == ', this.props)

      // console.log('auth().currentUser == ', auth().currentUser)
      // auth().getadd
      // const test = firebase.auth().currentUser
      // console.log('userAuth == ', userAuth)
      // const test = firebase.auth().currentUser?.reauthenticateWithPopup(new 
      // if (userAuth) {
      //   const result = await signInWithTwitter()
      //   this.props.setCredential(result.credential)
      // }
    })
  }

  render() {
    // console.log('user == ', this.props.user)
    // console.log('this.props.user == ', this.props.user)
    // console.log('this.props.credential == ', this.props.credential)
    // console.log('this.state.credentialSet == ', this.state.credentialSet)
    if (this.props.credential
      && this.props.user
      && !this.props.user.credential
      && !this.state.credentialSet
      // @ts-ignore
      && this.props.credential.accessToken) {
      console.log('Setting Credential, credential == ', this.props.credential)
      const { credential } = this.props
      this.props.setCredential(this.props.credential)
      this.setState({
        credentialSet: true,
      })
      Axios.get(
        // @ts-ignore
        `${API_BASE_URL}/twitter/username/${credential.accessToken}/${credential.secret}/${this.props.user.providerData[0].uid}`).then((user: any) => {
          // console.log('axios returned user == ', user.data)
          this.props.setTwitterProfile(user.data)
        })
    }

    if (!this.state.loading) {
      return (
        <UserContext.Provider value={{
          user: null,
        }}>
          {this.props.children}
        </UserContext.Provider>
      )
    } else {
      return (
        <LoadingSpinner />
      )
    }
  }
}

const mapStateToProps = (state: any) => ({
  userData: state.userDataReducer,
  user: state.user,
})

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setUser: (user: any) => dispatch(setUser(user)),
    setCredential: (credential: any) => dispatch(setCredential(credential)),
    setTwitterProfile: (twitterProfile: any) => dispatch(setTwitterProfile(twitterProfile)),
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(UserProvider)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  connect((state: any) => {
    // console.log('state == ', state)
    return ({
      initialValues: state.firestore.ordered.settings ? state.firestore.ordered.settings[0] : {
      },
    })
  }),
  spinnerWhileLoading(['uid', 'settings', 'drafts']),
  withRouter,
  // @ts-ignore
  connect(({ firebase: { auth: { uid } } }) => {
    // console.log('uid in connect == ', uid)
    return ({
      uid,
    })
  }),
  firestoreConnect(({ uid }: { uid: string }) => {
    // console.log('uid == ', uid)
    if (uid) {
      return [
        {
          collection: FirestoreCollections.SETTINGS, where: [['uid', '==', uid]],
        },
        {
          collection: FirestoreCollections.USERS, where: [['uid', '==', uid]],
        },
      ]
    } else {
      return []
    }
  }),
  connect((state: any, props: any) => {
    // console.log('state.firestore == ', state.firestore)
    const { uid } = state.firebase.auth
    return ({
      settings: state.firestore.ordered.settings,
      drafts: state.firestore.data.drafts,
      credential: state.firestore.data.users && state.firestore.data.users[uid] && state.firestore.data.users[uid].credential ? state.firestore.data.users[uid].credential : {
      },
      user: state.userReducer.user,
    })
  }),
  // @ts-ignore
)(UserProvider)