import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
// import classes from './LoadingSpinner.scss'

export const LoadingSpinner = ({ size }: { size: any }) => (
  <div className="loadingSpinnerContainer">
    <div className="loadingSpinnerProgress">
      {/*
      // @ts-ignore */}
      <CircularProgress mode="indeterminate" size={size || 80} color={'primary'} style={{
        color: '#eb5757',
        zIndex: 100000000,
      }} />
    </div>
  </div>
)

LoadingSpinner.propTypes = {
  size: PropTypes.number,
}

export default LoadingSpinner
