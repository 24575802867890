// import { UserSettings } from './types/user'

import { UserSettings } from './types/user'

export const DEFAULT_USER_SETTINGS: UserSettings = {
  endString: '/END',
  splitOnSentences: false,
  autoScroll: true,
}

export const TWEET_CODES = {
  FORCE_NEW_TWEET: '\n{!*!}\n',
  IMG_START: '\n{!img=',
  IMG_END: '!}\n',
}

export const TYPING_TIMEOUT = 2000

export const FirestoreCollections = {
  SETTINGS: 'settings',
  DRAFTS: 'drafts',
  SENT_THREADS: 'sentThreads',
  PENDING_CREDENTIALS: 'pendingCredentials',
  USERS: 'users',
}

export const dndItemTypes = {
  DRAFT_IMAGE: 'tweetImage',
}

export const dndDropTypes = {
  IMAGE_TRASH: 'imageTrash',
}

export const THREAD_TWEET_EXTRA_RADIO_VALUES = {
  REPLY_TO_TWEET: 'replyToTweet',
  QUOTE_TWEET: 'quoteTweet',
}