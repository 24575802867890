import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import AppContainer from './containers/AppContainer'
import UserProvider from './providers/UserProvider'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import firebase from 'firebase/app'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
// import rootReducer from './redux/rootReducer'
// import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { configureStore } from './redux/createStore'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import LoadingSpinner from './components/LoadingSpinner'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './containers/css/main.css'
import CookieConsent from 'react-cookie-consent'
import ReactGA from 'react-ga'
import { THREADR_PINK } from './util/colours'
// @ts-ignore
import { IntlProvider } from 'react-redux-multilingual'
import { translations } from './lang'

// @ts-ignore
ReactGA.initialize('UA-169253278-1', {
  debug: false,
  titleCase: false,
})
ReactGA.pageview(window.location.pathname + window.location.search)

const store = configureStore({
})

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
}

// const initialState = {}
// const store = createStore(rootReducer, initialState)

function AuthIsLoaded({ children }: { children: any }) {
  // @ts-ignore
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <LoadingSpinner />
  return children
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
}

class App extends Component {
  render() {
    console.log('trying to return')
    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale='en'>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <UserProvider>
              <AuthIsLoaded>
                <AppContainer />
                <ReduxToastr
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                  preventDuplicates={true}
                  timeOut={2000}
                />
                <CookieConsent style={{
                  background: THREADR_PINK,
                }}>
                  This website uses cookies to enhance the user experience.
                </CookieConsent>
              </AuthIsLoaded>
            </UserProvider>
          </ReactReduxFirebaseProvider>
        </IntlProvider>
      </Provider>
    )
  }
}

export default App

  // < Provider store = { store } >
  //   <ReactReduxFirebaseProvider {...rrfProps}>
  //     {/*
  //       // @ts-ignore */}
  //     <UserProvider>
  //       <AuthIsLoaded>
  //         <AppContainer />

  //         <ReduxToastr
  //           transitionIn="fadeIn"
  //           transitionOut="fadeOut"
  //           preventDuplicates={true}
  //           timeOut={99999}
  //         />
  //       </AuthIsLoaded>
  //     </UserProvider>
  //   </ReactReduxFirebaseProvider>
  //     </Provider >