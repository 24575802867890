
import firebase from 'firebase'
// import config from '../firebase.config.js'
/**
 * XXX : Test env
 */
// window.location.hostname
console.log('window.location.hostname == ', window.location.hostname)
let _ENV = 'test'
if (window.location.hostname === 'threadr.me') {
  _ENV = 'prod'
}

export const ENV = _ENV

let firebaseConfig = { // Test
  apiKey: 'AIzaSyAqVif491xh6D-pLFe39waDjaAvUTYyANU',
  authDomain: 'threadr-test.firebaseapp.com',
  databaseURL: 'https://threadr-test.firebaseio.com',
  projectId: 'threadr-test',
  storageBucket: 'threadr-test.appspot.com',
  messagingSenderId: '752533055673',
  appId: '1:752533055673:web:3825a616d554d29b415368',
  measurementId: 'G-0KLTJ5Q4S7',
}

if (window.location.hostname === 'threadr.me') {
  firebaseConfig = { // Production
    apiKey: 'AIzaSyAKOe6VkQDIaoF23Ookc0YAoTO7ehL4xNw',
    authDomain: 'threadr-2b305.firebaseapp.com',
    databaseURL: 'https://threadr-2b305.firebaseio.com',
    projectId: 'threadr-2b305',
    storageBucket: 'threadr-2b305.appspot.com',
    messagingSenderId: '1049559667666',
    appId: '1:1049559667666:web:adc129e98e25a00f13ee0b',
    measurementId: 'G-TSE5YZ91W1',
  }
}
// https://us-central1-threadr-test.cloudfunctions.net/twitter
let _API_BASE_URL = 'https://us-central1-threadr-test.cloudfunctions.net' // Test
if (firebaseConfig.projectId === 'threadr-2b305') { // Production
  _API_BASE_URL = 'https://us-central1-threadr-2b305.cloudfunctions.net'
}

export const API_BASE_URL = _API_BASE_URL


export const { auth, firestore } = firebase

export function signInWithTwitter() {
  // console.log('Trying to sign in with Twitter')
  const provider = new auth.TwitterAuthProvider()
  return auth().signInWithPopup(provider)
}

export default firebaseConfig