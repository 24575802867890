import React from 'react'
import {
  Navbar, Nav, NavDropdown, Button, Image,
} from 'react-bootstrap'
import { signInWithTwitter } from '../firebase'
import { connect } from 'react-redux'
import { auth } from '../firebase'
// import axios from 'axios'
import { setCredential, setTwitterProfile } from '../redux/slices/userSlice'
import { TwitterCredentials } from '../../shared/types/user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome, faCog, faSignOutAlt, faPen, faPaperPlane, faCalendar,
} from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from 'react-router-bootstrap'
import { NavLink } from 'react-router-dom'
import { firestore } from 'firebase'
import { FirestoreCollections } from '@shared/constants'

interface Props {
  userData: any
  setCredential: Function
  credential: TwitterCredentials
  setTwitterProfile: Function
}

interface State {
  isAuthenticated: boolean
  user: any
  token: string
}

class TopNav extends React.Component<Props, State> {

  constructor (props: Props) {
    super(props)
    this.state = {
      isAuthenticated: false, user: null, token: '',
    }
  }

  // componentDidMount() {
  //   console.log('this.props.user == ', this.props.userData)
  // }

  logout = () => {
    auth().signOut()
  }

  // testTweet = async () => {
  //   const { credential } = this.props
  //   console.log('credential == ', credential)
  //   await axios.post(`${API_BASE_URL}/twitter/tweet`, {
  //     user_key: credential.accessToken,
  //     user_secret: credential.secret
  //   })
  //   console.log('request finished')
  // }

  signIn = async () => {
    const result = await signInWithTwitter()
    console.log('result.additionalUserInfo == ', result.additionalUserInfo)
    this.props.setTwitterProfile(result?.additionalUserInfo?.profile)
    this.props.setCredential(result.credential)
    const user = await firestore().doc('/users/' + result.user?.uid).get()
    console.log('user == ', user.data())

    const anyCred: any = result.credential

    console.log('result == ', result)

    /**
     * If user is undefined, not yet in auth
     * Add credentials to pending collection
     * Trigger will then grab them when adding the user
     * XXX : If user does exist, replace credentials in firestore
     */

    if (!user.data() && result && result.user && result.credential) {
      console.log('In the user if')
      const cred: TwitterCredentials = {
        // uid: result.user.uid,
        // credential: {
        providerId: result.credential.providerId,
        signInMethod: result.credential.signInMethod,
        accessToken: anyCred.accessToken,
        secret: anyCred.secret,
        // }
      }
      console.log('before user creds in pending')
      const res =
        await firestore()
          .collection(FirestoreCollections.PENDING_CREDENTIALS)
          .doc(result.user?.uid)
          .set(cred)
      console.log('after res == ', res)
    } else if (user.data() && result && result.user && result.credential) {
      const cred: TwitterCredentials = {
        // uid: result.user.uid,
        // credential: {
        providerId: result.credential.providerId,
        signInMethod: result.credential.signInMethod,
        accessToken: anyCred.accessToken,
        secret: anyCred.secret,
        // }
      }
      // XXX : User is already in firestore so add credentials
      await firestore().collection(FirestoreCollections.USERS)
        .doc(result.user?.uid)
        .update({
          credential: cred,
        })
    }
  }

  render() {
    const { user } = this.props.userData
    // console.log('userData == ', this.props.userData)
    // console.log('photoUrl == ', user?.photoURL)
    // return (
    //   <div>Test</div>
    // )
    // fixed="top" 
    return (
      <Navbar className="threadrNav" bg="light" expand="lg">
        <Navbar.Brand href="#">
          <img
            src={process.env.PUBLIC_URL + '/images/logo_banner.png'}
            height={50}
            alt="Threadr.Me"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavLink className="navHome" to="/home">
              <FontAwesomeIcon
                className="navHomeIcon"
                style={{
                  cursor: 'pointer',
                }}
                icon={faHome} />
                  Home
            </NavLink>
            {user && (
              <React.Fragment>
                <NavLink className="navDrafts" to="/drafts">
                  <FontAwesomeIcon
                    className="navDraftsIcon"
                    style={{
                      cursor: 'pointer',
                    }}
                    icon={faPen} />Drafts
                </NavLink>
              </React.Fragment>
            )}
            {user && (
              <React.Fragment>
                <NavLink className="navDrafts" to="/sent">
                  <FontAwesomeIcon
                    className="navDraftsIcon"
                    style={{
                      cursor: 'pointer',
                    }}
                    icon={faPaperPlane} />Sent
                </NavLink>
              </React.Fragment>
            )}
            {/* {user && (
              <React.Fragment>
                <NavLink className="navSchedule" to="/schedule">
                  <FontAwesomeIcon
                    className="navScheduleIcon"
                    style={{
                      cursor: 'pointer',
                    }}
                    icon={faCalendar} />Schedule
                </NavLink>
              </React.Fragment>
            )} */}
            {/* {user && (
              <NavLink to="/posted">Posted</NavLink>
            )} */}
          </Nav>
          {/* <Button onClick={() => this.testTweet()}>TWEET</Button> */}
          {!this.props.userData.user ?
            <Button onClick={() => this.signIn()}>Sign In With Twitter</Button>
            : (
              <React.Fragment>
                <NavDropdown
                  className="userNavDropdown"
                  title={user?.displayName}
                  id="user-dropdown"
                >
                  {/* <span style={{ marginRight: '10px' }}>{user?.displayName}</span> */}
                  <LinkContainer to="/settings">
                    <NavDropdown.Item
                      href="#" >
                      <FontAwesomeIcon icon={faCog} /> Settings
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item
                    href="#"
                    onClick={() => this.logout()} >
                    <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
                  </NavDropdown.Item>
                </NavDropdown>
                <Image style={{
                  height: '40px',
                }} src={user.photoURL} roundedCircle />
              </React.Fragment>
            )
          }
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
  credential: state.userReducer.credential,
})

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCredential: (credential: any) => dispatch(setCredential(credential)),
    setTwitterProfile: (twitterProfile: any) => dispatch(setTwitterProfile(twitterProfile)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)