import { MAIN_TEXT_INPUT_PLACEHOLDER } from './strings'

export const langEn = {
  locale: 'en-US',
  messages: {
    buttonAddVideoToTweet: 'Add Video To Tweet',
    buttonClearTweetID: 'Clear Tweet ID',
    buttonQuoteTweetAdd: 'Quote This Tweet',
    buttonQuoteTweetCancel: 'Cancel',
    buttonReplyToTweet: 'Reply To Tweet',
    buttonSendingThread: 'Sending Thread.. ',
    buttonTweetThread: 'Tweet Thread',
    checkLabelAutoScroll: 'Auto Scroll With Typing',
    draftNotSaved: 'Not Saved',
    draftSaved: ' Saved',
    draftSaving: ' Saving',
    draftTitlePlaceHolder: 'You can give your thread a title to help organise your drafts',
    hello: 'how are you {name}',
    mainTextInputPlaceHolder: MAIN_TEXT_INPUT_PLACEHOLDER,
    menuAddImage: 'Add Image',
    menuAddVideo: 'Add Video',
    menuQuoteTweet: 'Quote Tweet',
    placeHolderAddImage: 'Add Image',
    placeHolderAddQuoteTweet: 'Add Quote Tweet',
    placeHolderAddVideo: 'Add Video',
    placeHolderMaxImagesVideos: 'Max 4 Images Or 1 Video Per Tweet',
    placeHolderMaxQuoteTweets: 'Only 1 Quote Tweet Per Tweet Allowed',
    placeHolderTweetExtras: 'Paste Tweet ID or Link to Tweet',
    placeHolderUploadVideo: 'Select your video',
    placeHolderQuoteTweetFileInput: 'Paste Tweet ID or Link to Tweet',
    threadTitle: 'Thread Title:',
    titleAddEmoji: 'Add Emoji',
    titleAddQuoteTweet: 'Add Quote Tweet',
    titleErrorSendingThread: 'Error Sending Thread',
    titleForceNewTweet: 'Force New Tweet',
    titleQuoteTweetModal: 'Quote A Tweet',
    titleRemoveVideo: 'Remove Video',
    titleThreadPreview: 'Thread Preview',
    titleRetweetThread: 'Retweet Thread',
    titleUploadVideo: 'Upload Video',
    titleScheduleContainer: 'Your Threadr.me Schedule',
    toastrThreadSentText: 'Thread Posted To Twitter',
    toastrThreadSentTitle: 'Sent',
  },
}